import React, { useState, useRef } from "react"
import dynamic from "next/dynamic"
import Cookies from "js-cookie"
import { useRouter } from "next/router"
import { UserLogout } from "../../../utils/gtm"
import useOutsideClick from "../useOutsideClick"

const ActiveLink = dynamic(() => import("../buttons/active-link"), {
  ssr: true,
})

function RewardsNavigation({ item, classList, punchhUserCookie }) {
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef()

  useOutsideClick(dropdownRef, () => {
    setIsOpen(false)
  })

  const openDropdown = () => {
    setIsOpen(!isOpen)
  }

  const router = useRouter()

  let userData;
  if (Cookies.get("punchh_authenticated_user")) {
      userData = JSON.parse(punchhUserCookie)
  }

  const handleLogOut = () => {
    if (Cookies.get("punchh_authenticated_user")) {
      // UserLogout(JSON.parse(Cookies.get("punchh_authenticated_user")).user_id, "authenticated")
      UserLogout("null", "false");
    }
    Cookies.remove("punchh_authenticated_user")
    router.push("/rewards")
  }

  return (
    <li
      className="mb-0 relative flex items-center"
      onMouseOver={() => setIsOpen(true)}
      onFocus={() => setIsOpen(true)}
      onMouseOut={() => setIsOpen(false)}
      onBlur={() => setIsOpen(false)}
      data-layer-menu_item={item.name}
    >
      <ActiveLink
        href={`${item.slugUrl}/`}
        classList="transition-all duration-300 text-xs pl-4 pt-4 pb-3 lg:pt-8 lg:pb-7 lg:py-8 uppercase hover:text-pink-500 flex items-center"
      >
        <svg className="fill-current w-5 h-5 mr-2.5 -mt-1 text-pink-500" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10-.001c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10Zm-4.93 16.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78a7.893 7.893 0 0 1-4.93 1.72c-1.86 0-3.57-.64-4.93-1.72Zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 0 1 2 9.999c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83ZM10 3.999c-1.94 0-3.5 1.56-3.5 3.5s1.56 3.5 3.5 3.5 3.5-1.56 3.5-3.5-1.56-3.5-3.5-3.5Zm0 5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5Z"/>
        </svg>
        {item.name}
      </ActiveLink>
      <button
        className="p-2"
        type="button"
        onClick={openDropdown}
        ref={dropdownRef}
        aria-label="dropdown"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={`fill-current text-ocean-500 w-3 h-2 transform transition-all duration-150 ${
            isOpen ? "rotate-180" : "rotate-0"
          }`}
          viewBox="0 0 12 9"
        >
          <path fill="#64C3BE" d="M6 9L.8 0h10.4L6 9z" />
        </svg>
      </button>
      {/* {isOpen && ()} */}
      <ul
        className={`${classList} shadow-md z-10 w-full min-w-200px bg-gray-400 transition-all duration-300 transform px-4 py-2 border-t-3px border-pink-500 ${
          isOpen ? "visible opacity-100" : "invisible opacity-0"
        }`}
      >
        {item.itemsCollection.items.map(subitem => (
          <li
            key={subitem.name}
            className="mb-0 w-full border-b border-metal-900 last:border-0"
            data-layer-menu_item={subitem.name}
          >
            <ActiveLink
              href={`${subitem.slugUrl}/`}
              classList="transition-all duration-300 text-xxs text-black px-2 pb-2 pt-3 first:pl-0 last:pr-0 uppercase block hover:text-pink-500"
            >
              {subitem.name}
            </ActiveLink>
          </li>
        ))}
        <li className="mb-0 pt-2 w-full border-b border-metal-900 last:border-0">
          <button
              type="button"
              onClick={handleLogOut}
              className="font-noto transition-all duration-300 text-xxs text-black w-full lg:w-auto pr-0 py-1 first:pl-0 hover:text-pink-500 uppercase flex items-center justify-start"
          >
              <svg className="w-5 h-5 mr-2.5 -mt-1" viewBox="0 0 20 18" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9 4 7.6 5.4 10.2 8H0v2h10.2l-2.6 2.6L9 14l5-5-5-5Zm9 12h-8v2h8c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2h-8v2h8v14Z" fill="#E6007D"/>
              </svg>
              Logout
          </button>
        </li>
      </ul>
    </li>
  )
}

export default RewardsNavigation
